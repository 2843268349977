import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// material
import { Stack, TextField, Typography, Button, Grid, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { singlePageSection, updatePageSection, API } from 'src/Redux/actions/pageSectionActions';
import { createImageFile } from 'src/Redux/helpers/generalHelper';
import Loader from 'src/components/Loader';
// ----------------------------------------------------------------------
const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/webp'];

export default function EditTargetedViews() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  // Getting Data from Redux
  const pageState = useSelector((state) => state.pageSections.items);

  useEffect(() => {
    setDataLoading(true);
    dispatch(singlePageSection('targeted_views'));
    setDataLoading(false);
  }, []);

  // Validation Schema for From
  const SectionSchema = Yup.object().shape({
    sectionMeta: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      Subtitle: Yup.string().required('Subtitle is required')
      // shortDescription: Yup.string().required('Short Description is required')
    })
  });
  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10} mx="auto">
        <Card sx={{ minWidth: 275, pt: 2, pl: 3, boxShadow: 3, borderRadius: 1 }}>
          <CardContent>
            {dataLoading ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  sectionMeta: {
                    title: pageState.sectionMeta ? pageState.sectionMeta.title : '',
                    Subtitle: pageState.sectionMeta ? pageState.sectionMeta.Subtitle : '',
                    shortDescription: pageState.sectionMeta
                      ? pageState.sectionMeta.shortDescription
                      : '',
                    image: pageState.sectionMeta ? pageState.sectionMeta?.image : ''
                  }
                }}
                validationSchema={SectionSchema}
                onSubmit={async (values) => {
                  const data = {
                    sectionName: 'Targeted Views',
                    sectionSlug: 'targeted_views',
                    pageId: '622b4abff6781e26ec7b3913',
                    active: true,
                    id: '62317f9f1a7b7c19e81cf791',
                    sectionMeta: {
                      title: values.sectionMeta ? values.sectionMeta.title : '',
                      Subtitle: values.sectionMeta ? values.sectionMeta.Subtitle : '',
                      shortDescription: values.sectionMeta
                        ? values.sectionMeta.shortDescription
                        : '',
                      image: values.sectionMeta
                        ? values.sectionMeta.image
                        : pageState.sectionMeta?.image
                    }
                  };
                  const newImage = values.sectionMeta.image,
                    currImage = pageState.sectionMeta?.image;
                  if (newImage !== currImage) {
                    const formData = new FormData();
                    const newImageFile = await createImageFile(values.sectionMeta?.image);
                    formData.append('image', newImageFile);
                    try {
                      const res = await API({
                        url: '/uploads',
                        method: 'post',
                        data: formData
                      });
                      data.sectionMeta.image = res.data.path;
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  const id = pageState._id;
                  dispatch(updatePageSection(data, id));
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                    navigate('/dashboard/pages/home');
                  }, 1000);
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form autoComplete="off">
                    <Typography variant="h4" gutterBottom>
                      Edit Home Section - Targeted Views
                    </Typography>
                    <Stack spacing={1} mt={2}>
                      <FormLabel id="radios">Title</FormLabel>
                      <Field
                        type="text"
                        id="title"
                        name="sectionMeta.title"
                        label="Enter Section Title"
                        className="input-style"
                        placeholder="Enter Section Title"
                      />
                      {errors.sectionMeta?.title && touched.sectionMeta?.title ? (
                        <div className="error-color">{errors.sectionMeta?.title}</div>
                      ) : null}
                      <FormLabel id="radios">Subtitle</FormLabel>
                      <Field
                        type="text"
                        id="Subtitle"
                        name="sectionMeta.Subtitle"
                        label="Enter Section SubTitle"
                        className="input-style"
                        placeholder="Enter Section SubTitle"
                      />
                      {errors.sectionMeta?.Subtitle && touched.sectionMeta?.Subtitle ? (
                        <div className="error-color">{errors.sectionMeta?.Subtitle}</div>
                      ) : null}
                      <FormLabel id="radios">Short Description</FormLabel>
                      <Field
                        type="text"
                        id="shortDescription"
                        name="sectionMeta.shortDescription"
                        label="Enter Section Short Description"
                        className="input-style"
                        placeholder="Enter Section Description"
                      />
                      {errors.sectionMeta?.shortDescription &&
                      touched.sectionMeta?.shortDescription ? (
                        <div className="error-color">{errors.sectionMeta?.shortDescription}</div>
                      ) : null}
                      <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} mt={2}>
                          <FormLabel id="image"> Preview</FormLabel>
                          <img
                            src={`${process.env.REACT_APP_NODE_ROOT}/uploads/${pageState.sectionMeta?.image}`}
                            alt={pageState.sectionMeta?.title}
                            width="255"
                            height="180"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} mt={2}>
                          <FormLabel id="image"> Upload New</FormLabel>
                          <br />
                          <TextField
                            sx={{ mt: 1 }}
                            id="file"
                            name="sectionMeta.image"
                            type="file"
                            onChange={(e) => {
                              const image = e.target.files[0];
                              if (!validTypes.includes(image.type)) {
                                toast.error('Please upload only .jpg, .jpeg & .png images');
                                return false;
                              }
                              const fileReader = new FileReader();
                              fileReader.onload = () => {
                                if (fileReader.readyState === 2) {
                                  setFieldValue('sectionMeta.image', fileReader.result);
                                  // setAvatarPreview(fileReader.result);
                                }
                              };
                              fileReader.readAsDataURL(image);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={loading}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>{' '}
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          to="/dashboard/pages/home"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
