// Auth Action
// Login User
export const LOGIN_USER_ATTEMPT = 'LOGIN_USER_ATTEMPT';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

// Create User
export const CREATE_USER_ATTEMPT = 'CREATE_USER_ATTEMPT';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

// Single User
export const SINGLE_USER_ATTEMPT = 'SINGLE_USER_ATTEMPT';
export const SINGLE_USER_FAIL = 'SINGLE_USER_FAIL';
export const SINGLE_USER_SUCCESS = 'SINGLE_USER_SUCCESS';

// Update User
export const UPDATE_USER_ATTEMPT = 'UPDATE_USER_ATTEMPT';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

// GET ALL USERS
export const GET_ALL_USERS_ATTEMPT = 'GET_ALL_USERS_ATTEMPT';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';

// DELETE USER
export const DELETE_USER_ATTEMPT = 'DELETE_USER_ATTEMPT';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

// FORGET PASSWORD
export const FORGET_PASSWORD_ATTEMPT = 'FORGET_PASSWORD_ATTEMPT';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';

export const AUTH_USER = 'auth_user';
// User Roles
// Create User Role
export const CREATE_USER_ROLE_ATTEMPT = 'CREATE_USER_ROLE_ATTEMPT';
export const CREATE_USER_ROLE_SUCCESS = 'CREATE_USER_ROLE_SUCCESS';
export const CREATE_USER_ROLE_FAIL = 'CREATE_USER_ROLE_FAIL';

// Single User Role
export const SINGLE_USER_ROLE_ATTEMPT = 'SINGLE_USER_ROLE_ATTEMPT';
export const SINGLE_USER_ROLE_SUCCESS = 'SINGLE_USER_ROLE_SUCCESS';
export const SINGLE_USER_ROLE_FAIL = 'SINGLE_USER_ROLE_FAIL';

// Get All User Role
export const GET_USER_ROLES_ATTEMPT = 'GET_USER_ROLES_ATTEMPT';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAIL = 'GET_USER_ROLES_FAIL';

// Update User Role
export const UPDATE_USER_ROLE_ATTEMPT = 'UPDATE_USER_ROLE_ATTEMPT';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';

// Delete User Role
export const DELETE_USER_ROLE_ATTEMPT = 'DELETE_USER_ROLE_ATTEMPT';
export const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS';
export const DELETE_USER_ROLE_FAIL = 'DELETE_USER_ROLE_FAIL';

// USER ACTION TYPE
export const IS_AUTH = 'IS_AUTH';
export const GET_USER = 'GET_USER';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const CLEAR_USER_SUCCESS_MESSAGE = 'CLEAR_USER_SUCCESS_MESSAGE';

// Categories Action
export const GET_CATEGORY_ATTEMPT = 'GET_CATEGORY_ATTEMPT';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';

// Add Category
export const CREATE_CATEGORY_ATTEMPT = 'CREATE_CATEGORY_ATTEMPT';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';

// Add Category
export const SINGLE_CATEGORY_ATTEMPT = 'SINGLE_CATEGORY_ATTEMPT';
export const SINGLE_CATEGORY_FAIL = 'SINGLE_CATEGORY_FAIL';
export const SINGLE_CATEGORY_SUCCESS = 'SINGLE_CATEGORY_SUCCESS';

// Update Category
export const UPDATE_CATEGORY_ATTEMPT = 'UPDATE_CATEGORY_ATTEMPT';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';

// Update Category
export const DELETE_CATEGORY_ATTEMPT = 'DELETE_CATEGORY_ATTEMPT';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

// Packages
// Add Package
export const CREATE_PACKAGE_ATTEMPT = 'CREATE_PACKAGE_ATTEMPT';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';

// Durations
// Add Duration
export const CREATE_DURATION_ATTEMPT = 'CREATE_DURATION_ATTEMPT';
export const CREATE_DURATION_FAIL = 'CREATE_DURATION_FAIL';
export const CREATE_DURATION_SUCCESS = 'CREATE_DURATION_SUCCESS';



// Update Duration
export const UPDATE_DURATION_ATTEMPT = 'UPDATE_DURATION_ATTEMPT';
export const UPDATE_DURATION_FAIL = 'UPDATE_DURATION_FAIL';
export const UPDATE_DURATION_SUCCESS = 'UPDATE_DURATION_SUCCESS';

// Add Category
export const SINGLE_PACKAGE_ATTEMPT = 'SINGLE_PACKAGE_ATTEMPT';
export const SINGLE_PACKAGE_FAIL = 'SINGLE_PACKAGE_FAIL';
export const SINGLE_PACKAGE_SUCCESS = 'SINGLE_PACKAGE_SUCCESS';

// Update Package
export const UPDATE_PACAKGE_ATTEMPT = 'UPDATE_PACAKGE_ATTEMPT';
export const UPDATE_PACAKGE_FAIL = 'UPDATE_PACAKGE_FAIL';
export const UPDATE_PACAKGE_SUCCESS = 'UPDATE_PACAKGE_SUCCESS';

// Update Package
export const DELETE_PACAKGE_ATTEMPT = 'DELETE_PACAKGE_ATTEMPT';
export const DELETE_PACAKGE_FAIL = 'DELETE_PACAKGE_FAIL';
export const DELETE_PACAKGE_SUCCESS = 'DELETE_PACAKGE_SUCCESS';

// Delete Duration
export const DELETE_DURATION_ATTEMPT = 'DELETE_DURATION_ATTEMPT';
export const DELETE_DURATION_FAIL = 'DELETE_DURATION_FAIL';
export const DELETE_DURATION_SUCCESS = 'DELETE_DURATION_SUCCESS';

// Single Duration
export const SINGLE_DURATION_ATTEMPT = 'SINGLE_DURATION_ATTEMPT';
export const SINGLE_DURATION_FAIL = 'SINGLE_DURATION_FAIL';
export const SINGLE_DURATION_SUCCESS = 'SINGLE_DURATION_SUCCESS';


// Edit Main Page
export const GET_PAGED_DATA = 'GET_PAGED_DATA';
export const UPDATE_PAGE = 'UPDATE_PAGE';

// Add Page Section
export const ADD_PAGE_SECTION_ATTEMPT = 'ADD_PAGE_SECTION_ATTEMPT';
export const ADD_PAGE_SECTION_FAIL = 'ADD_PAGE_SECTION_FAIL';
export const ADD_PAGE_SECTION_SUCCESS = 'ADD_PAGE_SECTION_SUCCESS';

// All Page Sections
export const GET_PAGE_SECTION = 'GET_PAGE_SECTION';
export const GET_PAGE_SECTION_FAIL = 'GET_PAGE_SECTION_FAIL';
export const GET_PAGE_SECTION_SUCCESS = 'GET_PAGE_SECTION_SUCCESS';

// Single Page Section
export const SINGLE_PAGE_SECTION = 'SINGLE_PAGE_SECTION';
export const SINGLE_PAGE_SECTION_FAIL = 'SINGLE_PAGE_SECTION_FAIL';
export const SINGLE_PAGE_SECTION_SUCCESS = 'SINGLE_PAGE_SECTION_SUCCESS';

// Update Page Section
export const UPDATE_PAGE_SECTION = 'UPDATE_PAGE_SECTION';
export const UPDATE_PAGE_SECTION_FAIL = 'UPDATE_PAGE_SECTION_FAIL';
export const UPDATE_PAGE_SECTION_SUCCESS = 'UPDATE_PAGE_SECTION_SUCCESS';

// Create Faq
export const CREATE_FAQ_ATTEMPT = 'CREATE_FAQ_ATTEMPT';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAIL = 'CREATE_FAQ_FAIL';

// Single Faqs
export const SINGLE_FAQS_ATTEMPT = 'SINGLE_FAQS_ATTEMPT';
export const SINGLE_FAQS_SUCCESS = 'SINGLE_FAQS_SUCCESS';
export const SINGLE_FAQS_FAIL = 'SINGLE_FAQS_FAIL';

// Get All Faqs
export const GET_FAQS_ATTEMPT = 'GET_FAQS_ATTEMPT';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAIL = 'GET_FAQS_FAIL';

// Update Faqs
export const UPDATE_FAQS_ATTEMPT = 'UPDATE_FAQS_ATTEMPT';
export const UPDATE_FAQS_SUCCESS = 'UPDATE_FAQS_SUCCESS';
export const UPDATE_FAQS_FAIL = 'UPDATE_FAQS_FAIL';

// Delete Faq
export const DELETE_FAQ_ATTEMPT = 'DELETE_FAQ_ATTEMPT';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';

// ALL BLOGS FEATURED
export const GET_ALL_BLOGS_ATTEMPT = 'GET_ALL_BLOGS_ATTEMPT';
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS';
export const GET_ALL_BLOGS_FAIL = 'GET_ALL_BLOGS_FAIL';

// ALL BLOGS ACTIVE
export const ALL_ACTIVE_BLOGS_ATTEMPT = 'ALL_ACTIVE_BLOGS_ATTEMPT';
export const ALL_ACTIVE_BLOGS_SUCCESS = 'ALL_ACTIVE_BLOGS_SUCCESS';
export const ALL_ACTIVE_BLOGS_FAIL = 'ALL_ACTIVE_BLOGS_FAIL';

// Create Blog
export const CREATE_BLOG_ATTEMPT = 'CREATE_BLOG_ATTEMPT';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_FAIL = 'CREATE_BLOG_FAIL';

// Single Blog
export const SINGLE_BLOG_ATTEMPT = 'SINGLE_BLOG_ATTEMPT';
export const SINGLE_BLOG_SUCCESS = 'SINGLE_BLOG_SUCCESS';
export const SINGLE_BLOG_FAIL = 'SINGLE_BLOG_FAIL';

// Update Blog
export const UPDATE_BLOG_ATTEMPT = 'UPDATE_BLOG_ATTEMPT';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL';

// Delete Blog
export const DELETE_BLOG_ATTEMPT = 'DELETE_BLOG_ATTEMPT';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAIL = 'DELETE_BLOG_FAIL';

// Create Review
export const CREATE_REVIEW_ATTEMPT = 'CREATE_REVIEW_ATTEMPT';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL';

// Single Review
export const SINGLE_REVIEW_ATTEMPT = 'SINGLE_REVIEW_ATTEMPT';
export const SINGLE_REVIEW_SUCCESS = 'SINGLE_REVIEW_SUCCESS';
export const SINGLE_REVIEW_FAIL = 'SINGLE_REVIEW_FAIL';

// Get All Review
export const GET_REVIEWS_ATTEMPT = 'GET_REVIEWS_ATTEMPT';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL';

// Update Review
export const UPDATE_REVIEW_ATTEMPT = 'UPDATE_REVIEW_ATTEMPT';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL';

// Delete Review
export const DELETE_REVIEW_ATTEMPT = 'DELETE_REVIEW_ATTEMPT';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL';

// For Orders
// Create Order
export const CREATE_ORDER_ATTEMPT = 'CREATE_ORDER_ATTEMPT';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

// Single Order
export const SINGLE_ORDER_ATTEMPT = 'SINGLE_ORDER_ATTEMPT';
export const SINGLE_ORDER_SUCCESS = 'SINGLE_ORDER_SUCCESS';
export const SINGLE_ORDER_FAIL = 'SINGLE_ORDER_FAIL';

// Get All Order
export const GET_ORDERS_ATTEMPT = 'GET_ORDERS_ATTEMPT';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

// Update Order
export const UPDATE_ORDER_ATTEMPT = 'UPDATE_ORDER_ATTEMPT';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

// Delete Order
export const DELETE_ORDER_ATTEMPT = 'DELETE_ORDER_ATTEMPT';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

// For Stripe Settings
// Create Stripe Setting
export const CREATE_STRIPE_SETTING_ATTEMPT = 'CREATE_STRIPE_SETTING_ATTEMPT';
export const CREATE_STRIPE_SETTING_SUCCESS = 'CREATE_STRIPE_SETTING_SUCCESS';
export const CREATE_STRIPE_SETTING_FAIL = 'CREATE_STRIPE_SETTING_FAIL';

// Single Stripe Setting
export const SINGLE_STRIPE_SETTING_ATTEMPT = 'SINGLE_STRIPE_SETTING_ATTEMPT';
export const SINGLE_STRIPE_SETTING_SUCCESS = 'SINGLE_STRIPE_SETTING_SUCCESS';
export const SINGLE_STRIPE_SETTING_FAIL = 'SINGLE_STRIPE_SETTING_FAIL';

// Get All Stripe Settings
export const GET_STRIPE_SETTINGS_ATTEMPT = 'GET_STRIPE_SETTINGS_ATTEMPT';
export const GET_STRIPE_SETTINGS_SUCCESS = 'GET_STRIPE_SETTINGS_SUCCESS';
export const GET_STRIPE_SETTINGS_FAIL = 'GET_STRIPE_SETTINGS_FAIL';

// Update Stripe Setting
export const UPDATE_STRIPE_SETTING_ATTEMPT = 'UPDATE_STRIPE_SETTING_ATTEMPT';
export const UPDATE_STRIPE_SETTING_SUCCESS = 'UPDATE_STRIPE_SETTING_SUCCESS';
export const UPDATE_STRIPE_SETTING_FAIL = 'UPDATE_STRIPE_SETTING_FAIL';

// Delete Stripe Setting
export const DELETE_STRIPE_SETTING_ATTEMPT = 'DELETE_STRIPE_SETTING_ATTEMPT';
export const DELETE_STRIPE_SETTING_SUCCESS = 'DELETE_STRIPE_SETTING_SUCCESS';
export const DELETE_STRIPE_SETTING_FAIL = 'DELETE_STRIPE_SETTING_FAIL';

// For Coupons
// Create Coupon
export const CREATE_COUPON_ATTEMPT = 'CREATE_COUPON_ATTEMPT';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL';

// Single Coupon
export const SINGLE_COUPON_ATTEMPT = 'SINGLE_COUPON_ATTEMPT';
export const SINGLE_COUPON_SUCCESS = 'SINGLE_COUPON_SUCCESS';
export const SINGLE_COUPON_FAIL = 'SINGLE_COUPON_FAIL';

// Update Coupon
export const UPDATE_COUPON_ATTEMPT = 'UPDATE_COUPON_ATTEMPT';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL';

// Delete Coupon
export const DELETE_COUPON_ATTEMPT = 'DELETE_COUPON_ATTEMPT';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL';

// For Addons
// Create Addon
export const CREATE_ADDON_ATTEMPT = 'CREATE_ADDON_ATTEMPT';
export const CREATE_ADDON_SUCCESS = 'CREATE_ADDON_SUCCESS';
export const CREATE_ADDON_FAIL = 'CREATE_ADDON_FAIL';

// Single Addon
export const SINGLE_ADDON_ATTEMPT = 'SINGLE_ADDON_ATTEMPT';
export const SINGLE_ADDON_SUCCESS = 'SINGLE_ADDON_SUCCESS';
export const SINGLE_ADDON_FAIL = 'SINGLE_ADDON_FAIL';

// Update Addon
export const UPDATE_ADDON_ATTEMPT = 'UPDATE_ADDON_ATTEMPT';
export const UPDATE_ADDON_SUCCESS = 'UPDATE_ADDON_SUCCESS';
export const UPDATE_ADDON_FAIL = 'UPDATE_ADDON_FAIL';

// Delete Addon
export const DELETE_ADDON_ATTEMPT = 'DELETE_ADDON_ATTEMPT';
export const DELETE_ADDON_SUCCESS = 'DELETE_ADDON_SUCCESS';
export const DELETE_ADDON_FAIL = 'DELETE_ADDON_FAIL';
